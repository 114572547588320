<template>
  <NuxtLink
    :to="localePath({ name: 'slug-shops', params: { slug: shop.slug } })"
    class="shop-item"
  >
    <div class="shop-item-cover">
      <BaseImageExternal
        v-if="shop.fileUrl"
        class="shop-cover"
        :src="shop.fileUrl"
        :alt="$t('shop.cover-alt', [shop.name])"
      />
    </div>
    <div class="shop-item-title">
      <BaseText class="card-title">{{ shop.name }}</BaseText>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { type ShopOverviewFragment } from "~/graphql/generated";

defineProps<{
  shop: ShopOverviewFragment;
}>();

const localePath = useLocalePath();
</script>

<style lang="scss" scoped>
.shop-item {
  background-color: $light-100;
  border: 1px solid $light-80;
  overflow: hidden;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .shop-item-cover {
    flex: 1;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shop-item-title {
    padding: 1rem;

    .card-title {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
